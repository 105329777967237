import React from "react"
import styled from "styled-components"
import Header from "../../components/Header"
import TextoInclinado from "../../components/TextoInclinado"
import FormaContacto from "../../components/FormaContacto"
import PhoneSVG from "../../components/homepage/PhoneSVG"
import Ecomm3Results from "../../components/homepage/Ecomm3Results"
import { graphql } from "gatsby"
import SEO from "../../components/SEO"
import LanguageSelector from "../../components/LanguageSelector"
const locale = "es"
const Homepage = styled.div`
  max-width: 100vw;
  width: 100wv;
  font-family: 'Comfortaa';
  background-color: white;
  margin: 12px;
  `
const Content = styled.h1`
  margin: 38px 0px;
  font-size: 24px;
  font-weight: 300;
  `
const RedText = styled.span`
  color: #FF4000;
  font-weight: 700;
`
export default ({data}) => {
  return(
    <Homepage>
      <SEO language={locale} />
      <Header locale={locale} />
      <Content>
        Creamos&nbsp;
        <RedText>
          tiendas online de eCommerce que venden y ganan premios.
        </RedText>
        <PhoneSVG data={data} />
        Ayudamos a compañias alrededor del mundo a&nbsp;
        <RedText>
          obtener lo mejor del comercio electrónico.
        </RedText>
        <Ecomm3Results data={data.allContentfulEComm3Results}/>
        <TextoInclinado/>
      </Content>
      <LanguageSelector locale={locale}/>
      <FormaContacto/>
    </Homepage>
  )
}
export const query = graphql`
  query Query3{
    allContentfulFeature {
      nodes {
        title
        content
        xCoordinate
        yCoordinate
        subFeatures
        icon {
          file {
            url
          }
        }
      }
    }
    allContentfulEComm3Results(filter: {node_locale: {eq: "es"}}) {
      nodes {
        bigText
        subtitle
        title
        node_locale
      }
    }
    fixed: file(relativePath: {eq: "iPhone.png"}) {
      id
      childImageSharp {
        fixed(width: 360, quality: 100) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
  }
`
