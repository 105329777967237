import React from "react"
import styled from "styled-components"
import Ticker from "./homepage/Ticker"
let Rotated = styled.div`
  padding-top: 100px;
  color: black;
  display: flex;
  flex-direction: column;
  justify-content: center;
  &>* {
    transform: rotate(-15deg);
    margin-bottom: 30px;
    font-weight: bold;
    height: 27px;  
  }
`
export default () => (
  <Rotated>
    <Ticker duration={5000} color={'#FF0000'}>Optimización de conversión</Ticker>
    <Ticker duration={30000} color={'#000000'}>Optimización de UI/UX</Ticker>
    <Ticker duration={7500} color={'#FF0000'}>Mejora del valor promedio de compra</Ticker>
  </Rotated>

)